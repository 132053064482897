/* Light theme vars*/
:root[data-theme="light"] {
  --background-color: #899faa;
  --heading-color: #253237;
  --heading-text-color: #253237;
  --content-item-color: #9DB4C0;
  --container-color: #899faa;
  --text-color: #333;
  --link-color: #007bff;
  --border-color: #ddd;
  --image-border-color: #0f0c0c;
}

/* Dark theme vars*/
:root[data-theme="dark"] {
  --background-color: #121212;
  --heading-color: #0e1416;
  --heading-text-color: #E0FBFC;
  --content-item-color: #9DB4C0;
  --container-color: rgb(37, 50, 54);
  --text-color: #eee;
  --link-color: #1a91da;
  --border-color: #444;
  --image-border-color: #666;
}

html {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.App {
  display: flex;
  justify-content: center;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.websites-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 2rem;
  background-color: var(--container-color);
}

.websites-container-info {
  border: 44px solid var(--heading-color);
  background-color: var(--container-color);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 2rem;
  z-index: 2;
}

.websites-container-info2 {
  border: 44px solid var(--heading-color);
  background-color: var(--container-color);
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 2rem;
}

.websites-container-content {
  padding-bottom: 33px;
  font-size: 1.5em;
  z-index: 2;
  text-align: center;
}

.websites-container-content a {
  text-decoration: underline;
  color: var(--heading-text-color);
}

.websites-container-content a:hover {
  color: var(--hover-color);
}

.websites-container-content ul {
  /* Remove default list styling */
  list-style-type: none;
  padding-left: 45px;
  padding-right: 45px;
}

.websites-container-content ul li {
  /* Styling for each list item */
  margin-bottom: 21px;
  position: relative;
  cursor: default;
  transition: all 0.3s ease;
  color: var(--heading-text-color);
}

.websites-container-content-addition {
  /* Styling for each addition */
  font-size: 1.5em;
  margin-bottom: 21px;
  margin-left: 51px;
  margin-right: 51px;
  position: relative;
  cursor: default;
  transition: all 0.3s ease;
  color: var(--heading-text-color);
}

.websites-container-content ul li:before {
  /* Custom bullet style */
  position: absolute;
  left: 0;
  color: var(--heading-text-color);
  /* Theme color for bullet */
  font-size: 1.5rem;
  line-height: 1;
}

.websites-container-content ul li:hover {
  /* Animation on hover */
  transform: translateX(10px);
  /* Color on hover */
  color: var(--hover-color);
}

:root[data-theme="light"] {
  --hover-color: #0e1416;
}

:root[data-theme="dark"] {
  --hover-color: var(--content-item-color);
}

.websites-container-content2 {
  position: relative;
  padding-bottom: 33px;
  font-size: 1.5em;
  text-align: left;
  z-index: 2;
  background-color: var(--container-color);
}

.websites-container-content2 ul {
  /* Remove default list styling */
  list-style-type: none;
  padding-left: 51px;
  padding-right: 51px;
}

.websites-container-content2 ul li {
  /* Styling for each list item */
  margin-bottom: 21px;
  padding-left: 21px;
  position: relative;
  cursor: default;
  transition: all 0.3s ease;
}

.websites-container-content2 ul li:before {
  /* Custom bullet style */
  content: '•';
  position: absolute;
  left: 0;
  color: var(--content-item-color);
  /* Theme color for bullet */
  font-size: 1.5rem;
  line-height: 1;
}

.websites-container-content2 ul li:hover {
  /* Animation on hover */
  transform: translateX(10px);
  color: var(--hover-color);
}

.website-preview {
  width: 300px;
  border: 1px solid var(--content-item-color);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0;
  margin-bottom: 6px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
}

.website-preview:hover {
  transform: translateY(-5px);
  border: 1px solid var(--content-item-color);
  transform: scale(1.02);
}

.website-preview:hover .website-image {
  opacity: 1;
}

.website-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  opacity: 0.93;
  transition: opacity 0.3s ease;
}

.info-pane {
  padding: 10px;
  background: #fff;
  margin: 0;
}

.info-pane p {
  margin: 0;
  padding: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
}

.overlay-enter {
  opacity: 0;
}

.overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.overlay-exit {
  opacity: 1;
}

.overlay-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90%;
  animation: scaleUp 0.3s ease-in-out;
  z-index: 99;
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.info-pane {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: var(--heading-color);
  color: #C2DFE3;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.website-preview:hover .info-pane {
  transform: translateY(0);
}

.website-title {
  color: #E0FBFC;
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  z-index: 2;
}

.website-info {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0 0;
}

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.split-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.left-column {
  display: flex;
  max-width: 30%;
  flex-direction: column;
}

.right-column {
  display: flex;
  max-width: 70%;
  flex-direction: column;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.parallax-layer-back {
  transform: translateZ(-3px) scale(4);
}

.parallax-layer-mid {
  transform: translateZ(-2px) scale(3);
}

.parallax-layer-front {
  transform: translateZ(-1px) scale(2);
}

.App-header {
  text-align: center;
}

.App-header h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.email a {
  text-decoration: none;
  font-size: 1.1em;
  color: var(--heading-text-color);
  transition: all 0.3s ease;
}

.email a:hover {
  /* change color for email hover */
  color: #C2DFE3;
  text-decoration: none;
}

.social-links {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  gap: 20px;
  z-index: 2;
}

.social-link {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
  z-index: 2;
}

.social-link-footer {
  text-decoration: none;
  transition: color 0.3s ease;
  z-index: 2;
}

.social-link-footer:hover {
  color: #C2DFE3;
}

.social-link span {
  margin-left: 8px;
}

.github-link:hover {
  color: #C2DFE3;
  /* Change color for GitHub hover */
}

.linkedin-link:hover {
  color: #C2DFE3;
  /* LinkedIn brand color for hover */
}

.social-link .faGithub,
.social-link .faLinkedin {
  font-size: 24px;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.name-heading {
  background-color: var(--heading-color);
  padding-top: 33px;
  padding-bottom: 33px;
  color: #E0FBFC;
  text-align: center;
}

.name-heading a {
  color: var(--content-item-color);
}

.name-heading h2 {
  font-size: 5em;
  text-align: center;
}

.name-heading2 {
  position: relative;
  text-align: center;
  color: var(--heading-text-color);
}

.websites-container-name {
  padding-top: 44px;
  color: var(--heading-text-color);
  text-align: center;
  font-size: 2em;
}

.websites-container-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 33px;
}

.layout-toggle-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 0.5rem;
  background-color: var(--container-color);
  border-radius: 8px;
}

.layout-container-space {
  flex-grow: 1;
}

.search-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-icon {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: var(--link-color);
}

.search-input {
  display: none;
  position: absolute;
  right: 100%;
  top: 0;
  width: 0;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  transition: width 0.3s ease, padding 0.3s ease;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--link-color);
}

.search-container:hover .search-input,
.search-input:focus {
  display:block;
  width: 200px;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
}

.project-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-toggle:hover {
  color: var(--heading-text-color);
  background-color: rgba(114, 57, 57, 0.05);
}

.project-toggle.active {
  color: var(--heading-text-color);
  background-color: rgba(0, 0, 0, 0.1);
}

.project-toggle:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--link-color);
}

.grid-layout .project-image {
  width: 350px;
  height: 233px;
}

.list-layout .project-image {
  width: 350px;
  height: 233px;
}

.list-layout .project-content {
  align-items: flex-start;
}

@media (max-width: 768px) {

  .grid-layout .project-image,
  .list-layout .project-image {
    width: 100%;
    height: auto;
  }
}

.layout-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: var(--link-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.layout-toggle-btn:hover {
  background-color: var(--link-hover-color);
}

.layout-toggle-btn svg {
  margin-right: 0.5rem;
}

.project-list-item {
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--container-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.project-list-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-list-item .project-title {
  color: var(--heading-text-color);
  margin-bottom: 1rem;
  font-size: 1.5em;
}

.project-list-item .project-content {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.project-list-item .project-content-left .project-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-content-right {
  flex: 1;
  text-align: left;
}

.project-list-item .project-image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.project-list-item .project-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-list-item .project-info p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.project-list-item .project-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--link-color);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.project-list-item .project-link:hover {
  background-color: var(--link-hover-color);
}

.project-list-item .project-improvements h3 {
  color: var(--heading-text-color);
  margin-bottom: 0.5rem;
}

.project-list-item .project-improvements ul {
  padding-left: 20px;
  margin: 0;
}

.project-list-item .project-improvements li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .project-list-item .project-content {
    flex-direction: column;
  }

  .project-list-item .project-image {
    width: 100%;
    height: auto;
  }
}

.server-list {
  position: relative;
}

.server-list li {
  position: relative;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.server-list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.server-image {
  position: absolute;
  top: 0%;
  right: calc(69% + 30px);
  transform: translateY(0%);
  max-width: 300px;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 10;
}

.server-list li:hover .server-image {
  opacity: 1;
}

.google-map-container {
  position: relative;
  padding-top: 9px;
  padding-bottom: 33px;
}

.google-map-container iframe {
  position: relative;
  z-index: 2;
}

.iframe {
  position: relative;
  z-index: 2;
}

@media (max-width: 792px) {
  .google-map-container iframe {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .split-container {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    max-width: 100%;
  }
}